<template>
  <div>
    <div class="new-article" v-if="isConnected">
      <h1 class="title">Modifier l'article</h1>
      <form @submit.prevent="patchArticle" class="newArticle-form">
        <div class="form-row title-row">
          <label for="title" class="form-label">Titre</label>
          <input type="text" v-model="editedArticle.title" value="article.title" name="title" id="title" class="form-input" required>
        </div>
        <div class="form-row">
          <vue-editor
            :editorOptions="editorSettings"
            :editorToolbar="customToolbar"
            v-model="editedArticle.content"
            class="editor"
          ></vue-editor>
        </div>
        <div class="form-btn">
          <button type="submit" class="btn">Envoyer</button>
        </div>
      </form>
    </div>
    <div v-else>
      <h1 class="title">Vous devez être connecté !</h1>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  props: {
    isConnected: { type: Boolean },
    editedArticle: { type: Object }
  },
  data() {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],

        [{ 'list': 'ordered'}, { 'list': 'bullet' }],

        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],

        [ 'link', 'video' ],
      ],
      editorSettings: {
        modules: {

        }
      }
    }
  },
  methods: {
    patchArticle() {
      this.$emit('patch-article', this.editedArticle)
      this.editedArticle.id = ''
      this.editedArticle.title = ''
      this.editedArticle.content = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  .newArticle-form {
    min-width: 100%;
    .form-row {
      flex-direction: column;
      align-items: center;
      .form-input {
        width: 90%;
      }
    }
  }
  .form-label {
    margin-bottom: 5px;
  }
}

.login {
  margin-top: 20px;
}

.newArticle-form {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.title-row {
  width: 50%;
  align-self: center;
}

.form-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.form-label {
  flex: 1;
  font-weight: 300;
  font-size: large;
}

.form-input {
  font-family: "Roboto", sans-serif;
  font-size: medium;
  flex: 3;
  border: 1px solid #dcdde1;
  border-radius: 2px;
  padding: 5px;
  background-color: #f8f8f8;
  &:hover {
    border-color: #093145;
    transition: 0.5s;
  }
  &:required:valid {
    border: 1px solid #dcdde1;
  }
  &:focus:required:invalid {
    border-color: red;
    box-shadow: none;
  }
  &:invalid {
    box-shadow: none;
  }
}

.form-btn {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  .btn {
    font-family: "Roboto", sans-serif;
    font-size: medium;
    border: 1px solid #dcdde1;
    background-color: #f8f8f8;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    &:hover {
      cursor: pointer;
      border-color: #093145;
      transition: 0.5s;
    }
  }
}

.editor {
  width: 100%;
  height: 500px;
  padding-bottom: 46px;
}
</style>